<template>
	<a-modal v-model="show" title="广告位置信息" :width="600" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="12">
					<a-form-model-item label="位置名称" label-position="top" prop="name">
						<a-input v-model="formData.name" placeholder="请输入广告名称"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="12">
					<a-form-model-item label="获取数量" label-position="top" prop="num">
						<a-input-number style="width:100%" :min="1" v-model="formData.num" placeholder="请输入获取数量"></a-input-number>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="12">
					<a-form-model-item label="图片高度" label-position="top" prop="height">
						<a-input-number style="width:100%" :min="0" v-model="formData.height" placeholder="请输入图片高度"></a-input-number>
					</a-form-model-item>
				</a-col>
				<a-col span="12">
					<a-form-model-item label="图片宽度" label-position="top" prop="width">
						<a-input-number style="width:100%" :min="0" v-model="formData.width" placeholder="请输入图片宽度"></a-input-number>
					</a-form-model-item>
				</a-col>
			</a-row>
		</a-form-model>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import upImg from '@/components/common/upImg.vue';
export default {
	components: { upImg },
	data() {
		return {
			show: false,
			loading: false,
			upimg: this.$refs.upimg,
			positionList: [],
			formData: {
				name: '',
				num: null,
				height: null,
				width: null
			},
			formRule: {
				name: [{ required: true, message: '请输入广告位置名称' }],
				num: [{ required: true, message: '请输入获取数量' }],
				height: [{ required: true, message: '请输入图片高度' }],
				width: [{ required: true, message: '请输入图片宽度' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					this.formData = JSON.parse(JSON.stringify(form));
				} else {
					this.formData.name = '';
					this.formData.num = null;
					this.formData.height = null;
					this.formData.width = null;
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					this.show = false;
					this.http
						.post('/platform/advertising/position/save', this.formData)
						.then(ret => {
							if (ret.code == 200) {
								utils.success('操作成功！').then(() => {
									utils.closeSpin();

									this.$emit('success');
								});
							}
						})
						.catch(err => {
							this.show = false;
							console.log(err);
							utils.closeSpin();
						});
				}
			});
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
.avatar-uploader > .ant-upload {
	width: 128px;
	height: 128px;
}
.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
